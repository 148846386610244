import * as React from 'react'
import Layout from '../components/Layout'
import * as Styles from '../styles/layout.module.scss'

function Datenschutz({ pageContext }) {

    return (
        <Layout title="Datenschutz">
            <div className={Styles.centeredLayout}>
                <h1>Datenschutz</h1>
                <h2>Formulare</h2>
                <p>Wir speichern lediglich die Daten von Ihnen, die Sie explizit in Formularfelder eingeben. Die Speicherung erfolgt auf den Servern der Firma Airtable, 155 5th St, San Francisco, California 94103. <b><a href="https://www.airtable.com/privacy">Deren Datenschutzbestimmungen können Sie hier einsehen.</a></b></p>
                <h2>Cookies</h2>
                <p>Wir verwenden Cookies lediglich nach Ihrer expliziten Zustimmung.</p>
                <h2>Allgemeine Hinweise</h2>
                <p>
                    Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend der gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung.</p>
                <p>
                    Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht.
                </p>
                <p>
                    Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.
                </p>
                <p>
                    Hinweis zur verantwortlichen Stelle
                    Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:
                    <br/>
                   <b>Brandeis Consulting GmbH</b> <br/>
                   Rheindammstr. 5 <br/>
                   68163 Mannheim <br/>
                   Geschäftsführer Jörg Brandeis <br/>
                   Amtsgericht Mannheim HRB 735382 <br/>

                   Telefon: +49 (0) 621 48494961 <br/>
                   E-Mail: info@brandeis.de<br/>
                   </p><p>
                   Auf unserer Website sind unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten eingebunden. Wenn diese Tools aktiv sind, können Ihre personenbezogene Daten in diese Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf hin, dass in diesen Ländern kein mit der EU vergleichbares Datenschutzniveau garantiert werden kann. Beispielsweise sind US-Unternehmen dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass US-Behörden (z.B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.
                   </p><h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
                   <p>
Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.

                </p>
                Verantwortlich im Sinne des Presserechts: Jörg Brandeis
            </div>
        </Layout>
    )
}

export default Datenschutz